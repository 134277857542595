$(document).ready(function() {

	//check user local to see if a redirect modal should be displayed
	localeNotice.init($('#locale-notice'));

	// $content is referenced in many scripts below
	var $content = $('#content');

	/*
	 * Vertical Alignment
	 */
	if ($('.vert-align-col').length > 0) {
		vertAlignCheck.init($('.vert-align-col'));
	}

	/*
	 * Initialize tabnav when needed
	 */
	$tabNav = $content.find('.tab-nav-menu');
	if ($tabNav.length > 0) {
		$tabNav.tabNav();
	}

	/*
	 * Picture Element
	 */
	// check if picture element is on page
	if ($('picture').length > 0) {
		// load picturefill plugin to add support if browser needs it
		pictureEl.init($('html'));
	}
	// check for the pictureEl when p13AfterDelivery happens
	$(document).on('p13nAfterDelivery', function (e, data) {
		// make sure p13n returns content for rendering
		if (data.snippetItems.length > 0 && data.snippetItems[0].rendering !== undefined) {
			// grab the contents of p13n delivery
			var $p13nContent = $(data.snippetItems[0].rendering);
			// test if the picture element is in the p13n contents
			if ($p13nContent.find('picture').length > 0) {
				// load picturefill plugin to add support if browser needs it
				pictureEl.init($('html'));
			}
		}
	});

	/*
	 * Background Image Show
	 */
	//remove the BG from the container on pages with bg-slideshow-container
	var $bgSlideshowContainer = $('.bg-slideshow-container');
	if ($bgSlideshowContainer.length) {
		$bgSlideshowContainer.closest('#container').css('background-image','none');
	}
	var bgSlideShowCount = 0;
	var bgSlideShowCheck = setInterval(function() {
		var $bgSlideshowItem = $('.bg-slideshow-item');
		if ($bgSlideshowItem.length > 0) {
			//stop checking for the bg-slideshow
			clearInterval(bgSlideShowCheck);

			var $bgSlideshow = $('.bg-slideshow'),
			    bgSlideshowIndex = 0;

			// set the id for image and link
			$bgSlideshowItem.each(function () {
				$(this).find('img').attr('id', 'bg-slide-img-' + bgSlideshowIndex);
				$(this).find('a').attr('id', 'bg-slide-link-' + bgSlideshowIndex);
				bgSlideshowIndex++;
			});

			//remove focus from the background slide link
			$bgSlideshowItem.find('a').on('click',function() {
				$(this).trigger('blur');
			});

			//initialize jCycle when there are multiple slides in the bg-slideshow
			if ( $bgSlideshowItem.length > 1 ) {
				jCycle.init(initBgSlideShow,$bgSlideshow);
			}
		}
		//stop checking for the video carousel after 5 seconds
		if (bgSlideShowCount < 100) {
			bgSlideShowCount++;
		} else {
			clearInterval(bgSlideShowCheck);
		}
	},50);

	//initialize jCycle when there are multiple slides in the slideshow
	if ( $('.slideshow > img').length > 1 ) {
        jCycle.init(initSlideShow, $('.slideshow'));
	}

	//initialize video gallery
	if( $('.video-container').length > 0 ) {
		initVideoGallery($('.video-container'));
	}

    //initialize horizontal carousel
    $content.on('p13nAfterDelivery', function (e, data) {
        $('.horz-carousel').each(function(){
            var $carousel = $(this);
            if (!$carousel.data('initialized') && $carousel.find('.horz-carousel-item').length > 1) {
                // set initialized true
                $carousel.data('initialized', true);
                // init jcycle
                jCycle.init(horzCarousel);
            }
        });
    });

    // registering p13nAfterDelivery for dynamic pricing for CMS pages 
    $content.on('p13nAfterDelivery', function (e, data) {
        $dynamicPricingLinks = $content.find('[data-pricing="true"]').not('[data-pricing-status]');
        if ($dynamicPricingLinks.length) {
            loadDynamicPricing.init($dynamicPricingLinks);
        }
    });

    // load dynamic pricing for CMS pages (excluding LTO pages because they have their own initialization)
    if (typeof isOnSale !== 'function') {
        var $dynamicPricingLinks = $content.find('[data-pricing="true"]');
        if ($dynamicPricingLinks.length) {
            loadDynamicPricing.init($dynamicPricingLinks);
        }      
    }

	//sticky bar
	var counterStickybar = 0;
	var checkStickybar = setInterval(function () {
		if ($('.cta-bar.sticky').length > 0) {
			//stop checking for sticky-bar
			clearInterval(checkStickybar);

			// only allow stickybar to be fixed if current page does not have a fixed header
			if (!$('.headerbar').hasClass('fixed')) {

				var $stickyBar = $('.cta-bar.sticky'),
					$stickyWrapper = $stickyBar.closest('.cta-wrapper'),
					$stickyWrapperParent = $stickyWrapper.parent(),
					stickyHeight = $stickyBar.outerHeight(),
					stickyPosition = $stickyWrapper.offset().top,
					browserScrollPosition = $(window).scrollTop(),
					stopPosition = ($stickyWrapperParent.offset().top + $stickyWrapperParent.outerHeight()) - stickyHeight;

				//set the min-height to reserve the space within the dom
				$stickyWrapper.css('min-height',stickyHeight);

				$(window).on('resize',function () {
					stickyHeight = $stickyBar.outerHeight();
					$stickyWrapper.css('min-height',stickyHeight);
				});

				$(window).on('scroll resize',function () {
					browserScrollPosition = $(window).scrollTop();
					stopPosition = ($stickyWrapperParent.offset().top + $stickyWrapperParent.outerHeight()) -  stickyHeight;

					if (browserScrollPosition > stickyPosition) {
						$stickyBar.addClass('fixed');

						if (browserScrollPosition < stopPosition) {
							$stickyBar.removeClass('stop');
						} else {
							$stickyBar.addClass('stop');
						}

					} else {
						$stickyBar.removeClass('fixed');
					}
				});
			}
		}

		//stop checking for sticky-bar after 5 seconds
		if (counterStickybar < 100) {
			counterStickybar++;
		} else {
			clearInterval(checkStickybar);
		}

	}, 50);

	//Hide the special offers button if the button is hidden in the header
	if (typeof(localeData) == "undefined" || localeData == null) {
		$(document).on('getLocale', function () {
			// hide special offers button at bottom of the page
			if (localeData.specialOffers == 'true' && hasLoggedIn()) {
				$('.article-aside').find('.special-offers-holder').addClass('hidden').prev('.col-sm-6').removeClass('col-sm-6');

				$('.article-aside').on('p13nAfterDelivery', function (e, data) {
					$('.article-aside').find('.special-offers-holder').addClass('hidden').prev('.col-sm-6').removeClass('col-sm-6');
				});
			}
		});
	} else {
		// hide special offers button at bottom of the page
		if (localeData.specialOffers == 'true' && hasLoggedIn()) {
			$('.article-aside').find('.special-offers-holder').addClass('hidden').prev('.col-sm-6').removeClass('col-sm-6');

			$('.article-aside').on('p13nAfterDelivery', function (e, data) {
				$('.article-aside').find('.special-offers-holder').addClass('hidden').prev('.col-sm-6').removeClass('col-sm-6');
			});
		}
	}


	// update the display of the special offers button based on specialOffersFlag
	specialOffersClick.init($('.article-container'));
	specialOffersClick.init($('.article-aside'));
	// also check after p13 has fired
	$('.article-container, .article-aside').on('p13nAfterDelivery', function (e, data) {
		specialOffersClick.init($(this));
	});

    // initialize floating cards height adjustment
	floatingCards.check();

	if ($('.rwd-accordion-wrapper').length > 0) {
		loadAccordion();
	}

    $(document).on('p13nAfterDelivery', function (e, data) {
        if (data.snippetItems.length > 0 && data.snippetItems[0].rendering !== undefined) {
          var $isItAccordion = $(data.snippetItems[0].rendering); // test if the rwd-accordion element is in the p13n contents
    
          if ($isItAccordion.find('.rwd-accordion-wrapper').length > 0 
                            && $('.rwd-accordion-wrapper').length > 0) {
             loadAccordion();
          }
        }
      });

	/*
	 * Lightboxes - custom call back to initialize a scripts for easytabs, accordions, vertical-align
	 */
	$('body').on('mfpAjaxContentAdded', function (e) {
		var magnificPopup = $.magnificPopup.instance,
            $svg = magnificPopup.content.find('.svg-icon,.svg-flag,.wide-wrapper,.cards-wrapper,.sbs-wrapper'),
            svgNotLoaded = !$svg.length && !$('.svg-library-wrapper').length;

		if (magnificPopup.content.find('.rwd-accordion-wrapper').length) {
			loadAccordion();
		}

		if (magnificPopup.content.find('.slideshow').length) {
			jCycle.init(initSlideShow,$('.slideshow'));
            slideMediaControls.check();
		}

		if (magnificPopup.content.find('.vert-align-col').length) {
			var lightboxVertAlignCount = 0;
			var lightboxVertAlignCheck = setInterval(function() {
				vertAlignCol('.vert-align-col');
				//stop checking for vert-align changes after 5 seconds
				if (lightboxVertAlignCount < 100) {
					lightboxVertAlignCount++;
				} else {
					clearInterval(lightboxVertAlignCheck);
				}
			},50);
		}

        if (svgNotLoaded) {
            loadSVGLibrary();
        }

        // Initialize aligning of floating cards
        floatingCards.check();

        // Init loc-link
    	if (magnificPopup.content.find('.loc-link').length > 0) {
    		loclink.init(magnificPopup.content);
    	}
	});


	// scroll page after p13 loads
	$('#content').on('p13nAfterDelivery', function (e, data) {
		var hash = DOMPurify.sanitize(window.location.hash);
		// need to make sure we filter out addThis url hash since it begins with a '.'
		if (hash != '' && hash.charAt(1) != '.') {
            hash = hash.replace(/%20/g, "");
			anchorScrollTo($(hash));
		}
	});

	/*
	 * Check for panorama
	 */
	if ($('#panorama-view').length > 0) {
		initPanorama($('#panorama-view'));
	}

	// for site search on the 404 page. to be updated later when we implement site search
	var $siteSearchForm = $('.site-search-form');
	if ($siteSearchForm.length > 0) {
		siteSearchForm.init($('#site-search-field'));
	}

    // initialize qualtrics survey and set the form id for brochure part of the website
    if (actionToolbar && window.location == window.parent.location) {
		actionToolbar.qltrFeedbackLink('SV_7PcHxkLs8QHKhA9');
	}

	/*
	 * Apply loc-link when on the page in the content area
	 */
	if ($content.find('.loc-link').length > 0) {
		loclink.init($content);
	}

    /*
	 * Initialize Play & Pause functionality for carousel slides - home, v2, v4
	 */
    slideMediaControls.check();
    
    /*
	 * V2 Accordion Slide
	 */
    var v2Accordion = {
        
        init: function ($triggerBtn, $toggleContainer) {
            // Hide (Collapse) the toggle containers on load
            $toggleContainer.not(':hidden').hide();
        
                // Switch the "Open" and "Close" state per click
                $triggerBtn.on('click',function(){
                    var $currentBtn = $(this);
                    if ($currentBtn.hasClass('active')) {
                        $currentBtn.removeClass("active").attr('aria-expanded','false');
                    }
                    else {
                        $currentBtn.addClass("active").attr('aria-expanded','true');
                    }
                    $currentBtn.next(".toggle_container").slideToggle("slow");
                });
        
                var anchorLinkURL = DOMPurify.sanitize(document.location);

                if (anchorLinkURL.match('#') && anchorLinkURL.indexOf('#stq') < 0) {
                  var anchorLink = '#' + (anchorLinkURL.split('#')[1]);
                  anchorLink = anchorLink.replace(/%20/g, "");
                  $(anchorLink).trigger('click');
                }        
                        
            }
    }
    var $triggerBtn = $('button.trigger, h4.trigger'),
    $toggleContainer = $('.toggle_container');

    if ($triggerBtn.length > 0) {
        v2Accordion.init( $triggerBtn, $toggleContainer );
      } else {
        $("#content").on('p13nAfterDelivery', function () {
            $triggerBtn = $('button.trigger, h4.trigger'),
            $toggleContainer = $('.toggle_container');
            //to load accordions after P13N is run, check if the accordion exists then register the click event    
                if ($triggerBtn.length > 0 ) {
                v2Accordion.init( $triggerBtn, $toggleContainer );
                }
        });
      } 
     
    // opens accordion content for links with same url
    $(document).on('click', '#article-side-content a', function(e) {
        var urlPath = DOMPurify.sanitize(window.location.pathname),
            anchor = DOMPurify.sanitize($(this).attr('href')).split('#'),
            anchorPath = anchor[0],
            $btn = $('#' + anchor[1]);

        if (urlPath === anchorPath) {
            // prevent jump so that we can use anchor scroll
            e.preventDefault();
            // open accordion content and move into view
            if (!$btn.hasClass('active')) {
                $btn.trigger('click').trigger('focus');
                anchorScrollTo($btn);
            }
            else {
                $btn.trigger('focus');
                anchorScrollTo($btn);
            }
        }
    });

    /*
	 * Anchor scroll click button for ADA
	 */
    var $dropDownBtn = $('.anchor-dropdown-btn');
    if ($dropDownBtn.length > 0)
        $dropDownBtn.on('click',function(e){
            e.preventDefault();
            var selectValue = DOMPurify.sanitize($('select:not(.anchor-scroll)').val());
            window.location = selectValue;
        });

    /*
	 * Return to previous page
	 */
    $('.previous-page').on('click',function(e){
        e.preventDefault();
        window.history.back();
    });


    /*
	 * Container focus on button click
     *
     * <div id="parent">
     *     <div class="something">...</div>
     * </div>
     * <a href="#" data-focus-target=".something" data-focus-container="#parent">
     *
	 */
    $(document).on('click','*[data-focus-target]', function () {
        var $btn = $(this),
            $focusTarget = $($btn.data('focusContainer')).length > 0
                           ? $($btn.data('focusContainer')).find($btn.data('focusTarget'))
                           : $($btn.data('focusTarget'));

        if (!$focusTarget.is(':focus')) {
            $focusTarget.attr('tabindex','0').trigger('focus');
        }
    });

    /*
     * Content Hub : Share Bar
     */
    var $window = $(window),
        $articleContainer = $('.hub-article'),
        $shareBar = $('.hub-article .hub-share-bar'),
        $shareButtons = $shareBar.find('a'),
        padding = {
            top: 15,        // article top padding
            bottom: 30,     // article bottom padding
            total: 45       // total bottom padding (includes container padding)
        };

    if ($shareBar.length > 0) {
        $window.on('windowScroll windowResize', function(){
            if (Modernizr.mq('(min-width: 48em)')) {
                // desktop
                var shareHeight = $shareBar.height(),           // 140
                    headerHeight = $('#headerbar').height(),    // 52
                    offSetTop = $articleContainer.offset().top - padding.top - headerHeight,
                    offSetBottom = $articleContainer.height() + $articleContainer.offset().top - padding.total - shareHeight - headerHeight,
                    marginVal = $articleContainer.height() - $shareBar.height() - padding.bottom;

                if ($window.scrollTop() > offSetTop) {
                    // past article start
                    if ($window.scrollTop() > offSetBottom) {
                        // past article end
                        $shareBar.css({'position': 'relative', 'top': marginVal});
                    }
                    else {
                        // between start and end
                        $shareBar.css({'position': 'fixed', 'top': padding.top + headerHeight});
                    }
                }
                else {
                    // top of page
                    $shareBar.css({'position': 'relative', 'top': '0'});
                }
            }
            else {
                // mobile
                $shareBar.css({'position': 'relative', 'top': '0'});
            }
        });

        // init add this for share bar
        $shareButtons.on('click',function() {
            var $btn = $(this);
            if (!window.addthis && !$shareBar.data('initialized')) {
                loadJS('//s7.addthis.com/js/250/addthis_widget.js#pubid=ra-4fc673615553b316', function() {
                    // prevent multiple init
                    $shareBar.data('initialized', true);
                    // trigger button click after script execution
                    var interval = setInterval(function() {
                        if (window.addthis.perfMarkers.render_toolbox) {
                            $btn.trigger('click');
                            clearInterval(interval);
                        }
                    }, 50);
                });
            }
        });
    }

        /**
     * articleBannerNav - drawer effect for mobile devices on cms pages with article banner
     * @type {Object}
     */
    var articleBannerNav = {

        /**
         * $btnWhatsOnPage - dom element (a button) on the page
         * @type {jQuery Object}
         */
        $btnWhatsOnPage: $('.btn-whats-on-page'),

        /**
         * $btnWhatsOnPage - contain holding the list of anchor tags on the site
         * @type {jQuery Object}
         */
        $articleAnchorDrawer: $('.article-anchor-drawer'),

        /**
         * collapseAnchorNav - simple effect to slideUp to close the drawer, and then clean up the markup to not effect tablet and desktop users. Also update the aria settings
         */
        collapseAnchorNav: function () {
            articleBannerNav.$articleAnchorDrawer.slideUp(function () {
                articleBannerNav.$articleAnchorDrawer.removeClass('expanded').removeAttr('style');
            });
        },

        /**
         * expandAnchorNav - simple effect to slideDown to open the drawer, and then clean up the markup to not effect tablet and desktop users. Also update the aria settings
         * @return {[type]} [description]
         */
        expandAnchorNav: function () {
            articleBannerNav.$articleAnchorDrawer.slideDown(function () {
                articleBannerNav.$articleAnchorDrawer.addClass('expanded').removeAttr('style');
            });
        },

        /**
         * updateAriaControls - updates aria controls when drawer is expanded vs collapsed
         * @param  {Boolean} isExpanded - true when the drawer expanded
         */
        updateAriaControls: function (isExpanded) {
            if (isExpanded) {
                articleBannerNav.$articleAnchorDrawer.attr('aria-hidden',false);
                articleBannerNav.$btnWhatsOnPage.attr('aria-expanded',true);
            } else {
                articleBannerNav.$articleAnchorDrawer.attr('aria-hidden',true);
                articleBannerNav.$btnWhatsOnPage.attr('aria-expanded',false);
            }
        },

        /**
         * checkAria - sets aria controls for mobile devices, and removes aria controls for desktop users
         * @param  {string} breakPoint - breakpoint code
         */
        checkAria: function (breakPoint) {
            if (articleBannerNav.breakPoint === 'xs' || articleBannerNav.breakPoint === 'sm') {
                articleBannerNav.updateAriaControls(articleBannerNav.$articleAnchorDrawer.is(':visible'));
            } else {
                articleBannerNav.$articleAnchorDrawer.removeAttr('aria-hidden');
            }
        },

        /**
         * init - init click event and resize listener
         */
        init: function () {
            articleBannerNav.$btnWhatsOnPage.on('click', function () {
                var isExpanded = articleBannerNav.$articleAnchorDrawer.is(':visible');
                if (isExpanded) {
                    articleBannerNav.collapseAnchorNav();
                    articleBannerNav.updateAriaControls(false);
                } else {
                    articleBannerNav.expandAnchorNav();
                    articleBannerNav.updateAriaControls(true);
                }
            });

            var currentBreakPoint = windowBreakPoint();

            // set aria controls for mobile devices on load
            articleBannerNav.checkAria(currentBreakPoint);

            // update aria controls for mobile as needed
            $(document).on('windowResize', function () {
                var newBreakPoint = windowBreakPoint();
                if (newBreakPoint !== currentBreakPoint) {
                    console.log('update aria controls...');
                    articleBannerNav.checkAria(newBreakPoint);
                    currentBreakPoint = newBreakPoint;
                } else {
                    console.log('do nothing to aria controls');
                }
            });
        },
    }

    // only initialize on when article-banner is on the page
    if ($('.article-banner').length > 0) {
        articleBannerNav.init();
    }

    // ugc gallery
    var $ugcGallery = $('.ugc-image-gallery');
    if ($ugcGallery.length > 0) {
        ugc.checkLocale($ugcGallery);
    }

    // cms standalone violator bar initialization
    if (typeof cmsJsonVar !== 'undefined' && cmsJsonVar){
        var cmsViolatorBarCheck = setInterval(function() {
            if (typeof pclViolatorBar !== "undefined") {
                clearInterval(cmsViolatorBarCheck);
                pclViolatorBar.localeCheck(cmsJsonVar);
            } 
            },50);
        
    }
    if (typeof pcSegmentData  !== 'undefined' && pcSegmentData ) {
        var pcSegmentDataCheck = setInterval(function () {
          if (typeof pcSegmentData !== "undefined") {
            clearInterval(pcSegmentDataCheck);
            loadJS('/js/global/p13n/pc_p13n_delivery.js');
          }
        }, 50);
      }
});

var floatingCards = {
    /*
     * check
     */
    check: function() {
        if ($('.floating-cards').length > 0) {
            floatingCards.init();
        }
    },
    /*
     * init
     */
    init: function() {
        $('.floating-cards').each(function () {
            var $floatingCards = $(this);

            $(window).on('resize', function () {
                alignFloatingCards($floatingCards);
            });

            var floatingCardsCounter = 0;
            var floatingCardsCheck = setInterval(function() {
                alignFloatingCards($floatingCards);

                if (floatingCardsCounter < 10) {
                    floatingCardsCounter++;
                } else {
                    clearInterval(floatingCardsCheck);
                }
            },500);
        });
    }
}

/**
 * [specialOffersClick - show special offers in stickybar if the user is not logged in and eligible for Special Offers]
 * @type {Object}
 */
var specialOffersClick = {
	/**
	 * [description]
	 * @param  {Object} $container [container of content]
	 */
	init: function ($container) {
		if (typeof(localeData) == "undefined" || localeData == null) {
			// wait for getLocale to run is localeData is undefined
			$(document).on('getLocale', function () {
				specialOffersClick.test($container);
			});
		} else {
			specialOffersClick.test($container);
		}
	},
	/**
	 * [description]
	 * @param  {Object} $container [container of content]
	 */
	test: function($container) {
		var $specialOffersBtn = $container.find('.special-offers-click, .cbn-special-offers');

		$specialOffersBtn.each(function() {
			var $thisBtn = $(this);

			// check to locale data
			if (localeData.specialOffers == 'true') {
				// display all buttons in sticky bar
				specialOffersClick.showAllBtns($thisBtn);
			} else {
				// hide special offers button in sticky bar
				specialOffersClick.hideSpecialOffers($thisBtn);
			}
		});
	},
	/**
	 * [description]
	 * @param  {Object} $thisBtn [special offers btn]
	 */
	showAllBtns: function($thisBtn) {
		if ($thisBtn.parent().hasClass('sticky-bar') || $thisBtn.parent().hasClass('cta-bar')) {
			$thisBtn.css('visibility','visible').next().css('visibility','visible');
		}
	},
	/**
	 * [description]
	 * @param  {Object} $thisBtn [special offers btn]
	 */
	hideSpecialOffers: function($thisBtn) {
		// hide special offers button
		$thisBtn.addClass('hidden');
		// display other buttons in sticky bar
		if ($thisBtn.parent().hasClass('sticky-bar') || $thisBtn.parent().hasClass('cta-bar')) {
			$thisBtn.next().css('visibility','visible');
		}
		// center other content in grey box when special offers is hidden
		if ($thisBtn.parent().hasClass('special-offers-holder')) {
			$thisBtn.closest('.special-offers-holder').addClass('hidden').prev('.col-sm-6').removeClass('col-sm-6');
		}
	}
}

function initPanorama($panorama) {
	if(!$.fn.panorama360){
		$.when(
			$.ajax({
				url: '/css/global/lightbox-panorama.css',
				cache: 'true',
				dataType: 'text'
			}),
			$.ajax({
				url: '/js/global/jquery/plugins/panorama/jquery.panorama.js',
				cache: 'true',
				dataType: 'script'
			})
		).then(function(css,js) {
			$("head").append("<style>" + css + "</style>");
			$panorama.panorama360();
		});
	}else{
		$panorama.panorama360();
	}
}

function loadAccordion(){
	if (typeof accordion == 'undefined') {
		$.loadScript('/js/global/jquery/plugins/princess-accordion/accordion.js', true, function () {
			initAccordion();
		});
	} else {
		initAccordion();
	}
}

function initAccordion() {
	accordion.init({
		'wrapper' : '.rwd-accordion-wrapper',
		'item' : '.rwd-accordion',
		'header' : '.rwd-accordion-header',
		'content' : '.rwd-accordion-content',
		'selectBtn' : '.rwd-accordion-header button',
		'autoScroll' : false
	});
}

function initSlideShow($slideshow) {
	$slideshow.cycle({
		fx: 'scrollHorz',
		swipe: true,
		speed: 1000,
		manualSpeed: 1000,
		pauseOnHover: true,
		timeout: 5000,
		prev: '~ .slideshow-prev',
		next: '~ .slideshow-next',
		pager: '.slideshow-thumb-nav',
		pagerTemplate: '',
		caption: '> .slideshow-caption',
		captionTemplate: '{{alt}}',
		log: false
	});

	if ($slideshow.closest('.slideshow-row').hasClass('not-ready')) {
		$slideshow.closest('.slideshow-row').removeClass('not-ready');
	}
}

function initBgSlideShow($bgSlideshow) {
    // ada - append custom pagerTemplate
    $bgSlideshow.find('.bg-slideshow-item').each(function(i) {
        var btn = '<button><span class="visuallyhidden">Slide ' + (i + 1) + '</span></button>',
            $btnContainer = $bgSlideshow.siblings('.bg-slideshow-nav');
        // append pagetemplate
        $btnContainer.append(btn);
    })
    $bgSlideshow.cycle({
        fx: 'fadeout',
        swipe: true,
        speed: 1000,
        manualSpeed: 1000,
        pauseOnHover: true,
        timeout: 5000,
        //autoHeight: false,
        slides: '> .bg-slideshow-item',
        prev: '~ .bg-slideshow-prev',
        next: '~ .bg-slideshow-next',
        pager: '~ .bg-slideshow-nav',
        pagerTemplate: '',
        log: false
    });

    // ada - append current slide text
    var currentSlideTxt = '<span class="current-slide visuallyhidden"> (Current Slide)</span>';
    $bgSlideshow.siblings('.bg-slideshow-nav').find('button').eq(0).append(currentSlideTxt);
    $bgSlideshow.on('cycle-update-view', function() {
        var slideIdx = $(this).data('cycle.opts').currSlide,
            $activeBtn = $(this).siblings('.bg-slideshow-nav').find('button').eq(slideIdx);

        $activeBtn.append(currentSlideTxt).siblings('button').children('.current-slide').remove();
    });
    if ($bgSlideshow.parent().hasClass('not-ready')) {
        $bgSlideshow.parent().removeClass('not-ready');
    }
}

function initVideoGallery($gallery) {
	//load new video with video-thumb-link is clicked
	$gallery.on('click','.video-thumb-link', function (e) {
		$gallery.find('.video-thumb').removeClass('active');
		$(this).closest('.video-thumb').addClass('active');
		loadVideo($(this));
	});

	//click the first thumbnail to load video
	$gallery.find('.video-thumb-link').eq(0).trigger('click');

	//activate video arrows when there is more than one video
	var videoThumbCount = $('.video-thumb').length;

	if (videoThumbCount > 1) {
		$gallery.find('.video-row').removeClass('not-ready');

		$gallery.on('click', '.video-controls', function (e) {
			var $videoControlBtn = $(this),
				activeIndex = $gallery.find('.video-thumb.active').index();

			//select previous video
			if ($videoControlBtn.hasClass('video-prev')) {
				if (activeIndex == 1) {
					$gallery.find('.video-thumb-link').eq(videoThumbCount-1).trigger('click');
				} else {
					$gallery.find('.video-thumb.active').prev().find('.video-thumb-link').trigger('click');
				}
			}

			//select next video
			if ($videoControlBtn.hasClass('video-next')) {
				if (activeIndex == videoThumbCount) {
					$gallery.find('.video-thumb-link').eq(0).trigger('click');
				} else {
					$gallery.find('.video-thumb.active').next().find('.video-thumb-link').trigger('click');
				}
			}
		});
	}
}

function loadVideo($videoThumbLink){
	var id = $videoThumbLink.data('video-id'),
		title = '<h2>' + $videoThumbLink.data('video-title') + '</h2>',
		content = $videoThumbLink.data('video-caption');

	$('#gallery-main-video').data('video-id',id).find('iframe').prop('src','//www.youtube.com/embed/' + id + '?rel=0&showinfo=0&autohide=1&wmode=transparent');

	$('#video-caption').html(title + content);
}

function initAddThis(){
	if (window.addthis) {
		addthis.toolbox('.addthis_toolbox');
		addthis.counter('.addthis_counter');
	}
	else{
		$.getScript('//s7.addthis.com/js/250/addthis_widget.js#pubid=ra-4fc673615553b316#async=1');
	}
}

/**
* Used to evaluate the height of $element(s) and set a min-height based on the tallest element
*
* @param {Object} $floating-cards object
*/
function alignFloatingCards($floatingCards) {
	var widthCat = windowCat($(window).width()),
		setMinHeight = true;

	if ($floatingCards.find('.card-col').hasClass('col-' + widthCat + '-12') && !$floatingCards.hasClass('owl-carousel')) {
        // extra conditional implemented for OWL - needs to still resize in mobile b/c of constant horizontal orientation
		setMinHeight = false;
	}
	if ($floatingCards.find('.card-title').length > 0) {
		equalHeight($floatingCards.find('.card-title'), setMinHeight);
	}
	if ($floatingCards.find('.card-content').length > 0) {
		equalHeight($floatingCards.find('.card-content'), setMinHeight);
	}
	if ($floatingCards.find('.card').length > 0) {
		equalHeight($floatingCards.find('.card'), setMinHeight);
	}
	if ($floatingCards.find('.article-caption').length > 0) {
		// setTimeout implemented for delay of OWL resize
        setTimeout(function(){
            equalHeight($floatingCards.find('.article-caption'), setMinHeight);
        }, 50);
	}
}


/**
 * [equalHeight] - Evaluate the height of a group of elements to set a min-height based on the tallest element
 *
 * @param {jQuery Object} $elements to adjust min-height
 * @param {Boolean} when setMinHeight is true, function will scan all $element(s) to find maxHeight
 */
function equalHeight($elements,setMinHeight) {
	$elements.css('min-height', 0);
	if (setMinHeight) {
		var maxHeight = findMaxHeight($elements);
		maxHeight = Math.round(maxHeight) + 1;
		$elements.css('min-height', maxHeight);
	}
}

/**
 * [findMaxHeight] - Return the height of the tallest item within a group of elements
 * @param  {jQuery Object} $elements to find max height out of group
 * @return {number} returns the max-height of all the elements
 */
function findMaxHeight($elements) {
	var maxHeight = 0;
	$elements.each(function () {
		var currentHeight = $(this).outerHeight();
		if (currentHeight > maxHeight) {
			maxHeight = currentHeight;
		}
	});
	return maxHeight;
}




/*

$(document).ready(function() {

	if($('#slideshow').length > 0){
	$('#slideshow').cycle();

	// Sequential image loading to reduce amount of data transfered on load
	$('#slideshow').on( 'cycle-before', function(e, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag ) {
		nextImg = $('#slideshow img').eq(optionHash.nextSlide + 1);
		if ( nextImg.attr("src") == "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=="){
			nextImg.attr("src", nextImg.attr("data-img"));
		}
	});
}

});

*/


/* for locale only */
function easytabsCallbacks($container) {
	$.fn.tabNav.easytabsCallbacks($container);
}
$.fn.tabNav.easytabsCallbacks = function ($container) {

	//pause the photo gallery when switching to another tab
	$container.on('easytabs:before', function (e, $clicked, $targetPanel, settings) {
		var $currentTab = $('.easy-tabs-panels > .active');
		if ($currentTab.find('.slideshow').length) {
			$('.slideshow').cycle('pause');
		}
	});

	//un-pause when the user returns to the photo gallery
	$container.on('easytabs:after', function (e, $clicked, $targetPanel, settings) {
		if ($targetPanel.find('.slideshow').length) {
			$('.slideshow').cycle('resume');
		}
		//set the min-height after each tab is loaded
		$targetPanel.closest('.article-container').css({'min-height': $targetPanel.outerHeight() });
	});

	var isFirstTabLoaded = false;

	//initalize galleries when content is loaded
	$container.on('easytabs:ajax:complete', function (e, $clicked, $targetPanel, response, status, xhr) {
		if ($targetPanel.find('.slideshow').length) {
			jCycle.init(initSlideShow,$('.slideshow'));
            slideMediaControls.check();
			console.log('init slide show');
		}
		if ($targetPanel.find('.video-container').length) {
			console.log('init video gallery');
			initVideoGallery($('.video-container'));
		}
		// first tab loaded
		if (!isFirstTabLoaded) {
			isFirstTabLoaded = true;
            // set the min-height
			$targetPanel.closest('.article-container').css({'min-height': $targetPanel.outerHeight() });
            // load svg library
            var $svg = $('.svg-icon,.svg-flag,.wide-wrapper,.cards-wrapper,.sbs-wrapper'),
                svgNotLoaded = !$svg.length && !$('.svg-library-wrapper').length;
            if (svgNotLoaded) {
                loadSVGLibrary();
            }
		}
	});
}


//Swiftype Install Code - Beacon. This script notifies the Swiftype crawler when a page has been updated.
loadJS('//s.swiftypecdn.com/cc/wtsyxuhuynEBbpajna-R.js');

/**
 * loadDynamicPricing - asynchronously loading the dynamic pricing plugin when needed
 * @type {Object}
 */
var loadDynamicPricing = {
    /**
     * [isInit - know when loading of the plugin is in process]
     * @type {Boolean}
     */
    isInit: false,

    /**
     * [isReady - know when plugin is loaded]
     * @type {Boolean}
     */
    isReady: function () { return typeof ($.fn.dynamicPricing) === 'function'; },

    /**
     * [init - run dynamicPrcing when plugin is loaded, otherwise load plugin (only once)
     * @param  {jQuery Object} $productLink - link that has the product parameters
     * @param  {object} options - link that has the product parameters
     */
    init: function ($products, options) {       
        $products.attr('data-pricing-status','pending');

        if (loadDynamicPricing.isReady()) {
            loadDynamicPricing.run($products, options);
        } else {
            if (!loadDynamicPricing.isInit) {
                loadDynamicPricing.load(options);
            }
        }
    },
    
    /**
     * [load - load the plugin on the page, and then run on pending product links
     */
    load: function (options) {
        loadDynamicPricing.isInit = true;
        loadJS('/js/global/jquery/plugins/dynamic-pricing/jquery.dynamic-pricing.js', function () {
            loadDynamicPricing.run($('[data-pricing-status="pending"]'), options);
        });
    }, 

    /**
     * [run - apply dynamic pricing plugin to product elements
     * @param  {[type]} $products [description]
     * @return {[type]}           [description]
     */
    run: function ($products, options) {
        var settings = options || {};
        if (!!localeData) {
            settings.userCountry = localeData.country;
            $products.dynamicPricing(settings);
        } else {
            $(document).on('getLocale', function () {
                settings.userCountry = localeData.country;
                $products.dynamicPricing(settings);
            });
        }
    }
}

/*
 * Check to see if element is in viewport
 */
$.fn.isOnScreen = function(threshold) {
    var win = $(window);
    var viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };

    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height() - 50;

    var bounds = this.offset(),
        boundsTop = threshold ? bounds.top - threshold : bounds.top;

    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();

    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < boundsTop || viewport.top > bounds.bottom));
};

/**
 * Slide Media Controls
 */
var slideMediaControls = {
    /**
	 * check - checks to see if slide show container exists, then runs through interval to check if p13 content slides have loaded
	 */
    check: function() {
        var slideShowExists = $('.slideshow, .bg-slideshow').length > 0;
        if (slideShowExists) {
            slideMediaControls.slideCheck();
        }
    },

    /**
	 * slideCheck - checks to see if one than one slide exists before initializing
	 */
    slideCheck: function() {
        var carouselCount = 0,
            carouselCheck = setInterval(function() {

            $('.slideshow, .bg-slideshow').each(function(){
                var $slideShow = $(this);
                if (!$slideShow.data('initialized') && $slideShow.find('.cycle-slide').length > 0) {
                    $slideShow.data('initialized', true);
                    clearInterval(carouselCheck);

                    if ($slideShow.find('.cycle-slide').length > 1) {
                        slideMediaControls.init($slideShow);
                    }
                }
            });

            if (carouselCount < 100) {
                carouselCount++;
            } else {
                clearInterval(carouselCheck);
            }

        },50);
    },

    /**
	 * init - checks to see if multiple slides exist, if so then initialize toggle functionality and unhide pause/play button
	 */
    init: function($slideShow) {
        var $pauseBtn = $slideShow.siblings('.pause-play-btn'),
            $arrows = $slideShow.siblings('.slideshow-controls');

        // initialize toggle
        $pauseBtn.toggle(function() {
            slideMediaControls.pause($slideShow, $(this));
        }, function() {
            slideMediaControls.play($slideShow, $(this));
        });

        // remove hidden classes
        $pauseBtn.removeClass('hidden');
        $arrows.removeClass('hidden');
    },

    /**
	 * pause - pauses slide, updates aria label and icon
	 */
    pause: function($slideShow, $this) {
        $this.find('span').text('Play').removeClass('white-pause-10').addClass('white-play-10');
        $slideShow.cycle('pause');
    },

    /**
	 * play - resumes slide, updates aria label and icon
	 */
    play: function($slideShow, $this) {
        $this.find('span').text('Pause').removeClass('white-play-10').addClass('white-pause-10');
        $slideShow.cycle('resume');
    }
}

/*
 * Horizontal Carousel
 */
function horzCarousel() {
	loadJS('/js/global/horz-carousel.js', function(){
        initHorzCarousel();
    });
}

/*
 * JCycle 2
 */
var jCycle = {
    /**
	 * [init] - checks to see if jcycle alread exists and initialize appropriate function
     * @param {function} fn     : function to run after js has loaded
     * @param {[object]} param  : function's parameter (optional)
	 */
    init: function(fn, param) {
        //console.log('*** JC : initializing ***');
        var $jcycleExists = typeof $.fn.cycle == 'function';

        if ($jcycleExists) {
            //console.log('*** JC : function exists ***');
            jCycle.executeFn(fn, param);
        }
        else {
            jCycle.load(fn, param);
        }
    },
    /**
	 * [load] - loads cycle2 js
     * @param {function} fn     : function to run after js has loaded
     * @param {[object]} param  : function's parameter (optional)
	 */
    load: function(fn, param) {
        //console.log('*** JC : loading js ***');

        // conditionally load js and plugins
        var jcycleHasLoaded = false,
            jcycleSwipeHasLoaded = false,
            jcycleCarouselHasLoaded = false;

        loadJS('/js/global/jquery/plugins/cycle2/jquery.cycle2.min.js', function () {
            jcycleHasLoaded = true;

            loadJS('/js/global/jquery/plugins/cycle2/jquery.cycle2.swipe.min.js', function () {
                jcycleSwipeHasLoaded = true;
            });

            loadJS('/js/global/jquery/plugins/cycle2/jquery.cycle2.carousel.min.js', function () {
                jcycleCarouselHasLoaded = true;
            });
        });

        // trigger event after all scripts have been loaded
        var count = 0;
        var interval = setInterval(function() {
            if (jcycleHasLoaded && jcycleSwipeHasLoaded && jcycleCarouselHasLoaded) {
                jCycle.executeFn(fn, param);
                clearInterval(interval);
            }

            if (count < 200) {
                count++;
            }
            else {
                clearInterval(interval);
            }
        },50);
    },
    /**
	 * [executeFn] - executes pass through function if its not undefined
     *               Ensure the following scenarios:
     *             - js hardcoded in the page
     *             - js has already been loaded through load JS
     * @param {function} fn     : function to run after js has loaded
     * @param {[object]} param  : function's parameter (optional)
	 */
    executeFn: function(fn, param) {
        // check to see is passed function exists
        if (!fn) {
            return false;
        }
        // execute pass through function
        fn(param);
        //console.log('*** JC : Completed ***');
    }
}


/*
 * enableAutoPop - give WebOps the ability to control which pages will have the auto special offers lightbox
 * note : after test complete, set as doc ready again; clear condition on trade var
 */
function enableAutoPop($content, tradeVal) {
    if ($content.data('autoSpo') == true) {
		var delay = $content.data('autoSpoDelay'),     // number of milliseconds
		    code = $content.data('autoSpoCode'),       // WWWSPCLOFR (default)
		    section = $content.data('autoSpoSection'), // learn,cruise-destinations
		    trade = tradeVal !== undefined             // will remove destinations options from popup
                    ? tradeVal
                    : $content.data('autoSpoTrade');

		//if code is not provided, throw an error and stop
		if (typeof code == 'undefined' || code == '') {
			return false;
		}
		//if section is not provided, throw an error and stop
		if (typeof section == 'undefined' || section == '') {
			return false;
		}
		//run the call to begin the auto special offers
		autoSpecialOffers.init({
            'debug':true,
            'delay': delay,
            'code': code,
            'section': section,
            'trade': trade
        });
	}
}


/**
 * Check if Owl Carousel has loaded - this is used when owl carousel is part of P13N content or Adobe Target
 */
$(function(){
    var chekOwl = setInterval(function () {
        if ($('.carousel-container').length > 0) {
            owl.check();
        }
        clearInterval(chekOwl);
    },0);
});

var owl = {

    /**
     * [module] - returns carousel options for initialization based on carousel class
     * @param {[object]} $carousel  : carousel object
     * @return {[object]} [options] : module's carousel options
     */
    module: function($carousel) {
        switch (true) {
            case $carousel.hasClass('horz-carousel'):
                $carousel.parent('.carousel-container').addClass('owl-horz-carousel');
                return owl.options.horizontal;
                break;
            case $carousel.hasClass('full-width-slideshow'):
                $carousel.parent('.carousel-container').addClass('owl-bg-slideshow');
                return owl.options.bgSlideshow;
                break;
            default:
                $carousel.parent('.carousel-container').addClass('owl-slideshow');
                return owl.options.default;
        }
    },

	/**
     * [check] - checks to see function exists, then loads and/or initializes
     */
    check: function() {
        var owlExists = typeof $.fn.owlCarousel == 'function';

        if (owlExists) {
            owl.checkP13Content();
        }
        else {
            owl.load();
        }
    },

    /**
     * [load] - load owl js
     */
    load: function() {
        loadJS('/js/global/owl-carousel/owl.carousel.js', function () {
            owl.checkP13Content();
        });
    },

    /**
     * [checkP13Content] - check for p13 content before initializing
     */
    checkP13Content: function() {
        if ($('.owl-carousel').length) {
            owl.init();
        }
        else {
            $(document).on('p13nAfterDelivery', function () {
                owl.init();
            });
        }
    },

    /**
     * [init] - checks to see if more than one slide exists and if it has already been initialized
     *        - set data attribute to prevent re-initialization; initialize owl carousel
     */
    init: function() {
        // initialize carousels
        $('.owl-carousel').each(function(){
            var $carousel = $(this);
            if (!$carousel.data('initialized')) {
                var isSingleSlide = $carousel.children().length === 1,
                    moduleOptions = isSingleSlide ? owl.options.singleSlide : owl.module($carousel);

                // set initialized true
                $carousel.data('initialized', true);
                // init owl carousel
                $carousel.owlCarousel($.extend({}, owl.options.default, moduleOptions, owl.events($carousel)));
                // add additional classes if single slide
                isSingleSlide && $carousel.addClass('owl-loaded')
                    .siblings('.owl-media').addClass('disabled')
                    .closest('.owl-ratio-container').addClass('owl-single-slide');
            }
        });
        // load svg library
        var svgNotLoaded = !$('.svg-library-wrapper').length;
        if (svgNotLoaded) {
            loadSVGLibrary();
        }
    },

    /**
     * [enableTabOrder] - add appropriate tab index to slides based which slides are visible
     * @param {[object]} event          : owl plugin event object
     * @param {int} itemIndex           : slide index
     * @param {[object]} $slideAnchor   : slide anchor link
     */
    enableTabOrder: function(event, itemIndex, $slideAnchor) {
        // setTimeout for onChanged event to finish
        setTimeout(function(){
            // re-set tabindex to -1
            $slideAnchor.attr('tabindex','-1');
            // set visible slide anchors to 0
            $slideAnchor.slice(itemIndex, itemIndex + event.page.size).attr('tabindex','0');
        },50)
    },

    /**
     * [options] - owl plugin options
     */
    options: {
        /**
         * [default] - slideshow
         */
        default: {
            loop: true,
            autoplay: true,
            dots: true,
            margin: 0,
            lazyLoad: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            navElement: 'button',
            navText: ['<svg aria-hidden="true" class="svg-icon icon-chevron left"><use xlink:href="#icon-chevron" /></svg>','<svg aria-hidden="true" class="svg-icon icon-chevron"><use xlink:href="#icon-chevron" /></svg>'],
            nav: true,
            responsiveRefreshRate: 10,
            responsive: {
                0:{
                    items: 1
                }
            }
        },
        /**
         * [horizontal] - horz-carousel
         */
        horizontal: {
            loop: false,
            autoplay: false,
            dots: false,
            margin: 10,
            navText: ['<svg aria-hidden="true" class="svg-icon icon-chevron circle left"><use xlink:href="#icon-chevron" /></svg>','<svg aria-hidden="true" class="svg-icon icon-chevron circle"><use xlink:href="#icon-chevron" /></svg>'],
            responsive: {
                0:{
                    items: 1,
                    stagePadding: 50
                },
                640:{
                    items: 2
                },
                768:{
                    items: 3
                }
            }
        },
        /**
         * [bgSlideshow] - bg-slideshow
         */
        bgSlideshow: {
            animateOut: 'fadeOut',
            nav: false
        },
        /**
         * [singleSlide] - single slide
         */
        singleSlide: {
            loop: false,
            autoplay: false,
            mouseDrag: false,
            margin: 10,
            lazyLoad: false,
            responsiveRefreshRate: 10,
            responsive: {
                0:{
                    items: 1
                }
            }
        }
    },

    /**
     * [events] - owl plugin events
     * @param {[object]} $carousel  : carousel object
     * @return {[object]} [event]   : module's carousel events
     */
    events: function($carousel) {
        var obj = {
            /**
             * [onInitialized] - owl on initialized event
             * @param {[object]} event      : owl plugin event object
             */
            onInitialized: function(event) {
                var $container = $carousel.parent(),
                    $mediaContainer = $carousel.siblings('.owl-media'),
                    $pauseBtn = $mediaContainer.find('button'),
                    $dots = $container.find('.owl-dot');

                // set aria label for navigation buttons
                owl.setBtnLabel($carousel);
                // set aria label for dots
                owl.setDotsLabel($dots);
                // media control
                if ($pauseBtn.length > 0) {
                    // add aria-label to pause button
                    $pauseBtn.attr('aria-label', 'Pause');
                    // add disabled class if autoplay is false
                    if (owl.module($carousel).autoplay === false) {
                        $mediaContainer.addClass('disabled');
                    }
                    // event handler for toggle pause/play
                    $pauseBtn.on('click',function() {
                        if ($carousel.hasClass('paused')) {
                            // owl play event
                            $carousel.trigger('play.owl.autoplay').removeClass('paused');
                            // swap icons
                            owl.swapIcons($pauseBtn, 'play');
                            // swap aria-label
                            $pauseBtn.attr('aria-label', 'Pause');
                        }
                        else {
                            // owl stop event
                            $carousel.trigger('stop.owl.autoplay').addClass('paused');
                            // swap icons
                            owl.swapIcons($pauseBtn, 'pause');
                            // swap aria-label
                            $pauseBtn.attr('aria-label', 'Play');
                        }
                    });
                }
            },
            /**
             * [onChanged] - owl on changed event
             * @param {[object]} event      : owl plugin event object
             */
            onChanged: function(event) {
                var $navContainer = $carousel.siblings('.owl-nav'),
                    itemIndex = event.item.index,
                    $slideAnchor = $carousel.find('.owl-item a'),
                    $pauseBtn = $carousel.siblings('.owl-media').find('.owl-play-pause');

                // set aria live event
                owl.setAriaLive(event, $carousel);
                // set aria attribute for slides and arrows
                owl.setAriaHidden(event, $carousel, itemIndex, $navContainer);
                // set tab order - workaround for active slide
                if ($slideAnchor.length > 0) {
                    owl.enableTabOrder(event, itemIndex, $slideAnchor);
                }
                // resume playback on slide
                if ($carousel.hasClass('paused')) {
                    $pauseBtn.trigger('click');
                }
            },
            /**
             * [navContainer] - enables custom nav container
             */
            navContainer: $carousel.siblings('.owl-nav'),
            /**
             * [dotsContainer] - enables custom dots container
             */
            dotsContainer: $carousel.siblings('.owl-dots')
        }

        return obj;
    },

    /**
     * [setAriaHidden] - sets and toggles aria hidden attribute for arrows and slides
     * @param {[object]} event          : owl plugin event object
     * @param {[object]} $carousel      : carousel object
     * @param {int} itemIndex           : slide index
     * @param {[object]} $navContainer  : arrows container object
     */
    setAriaHidden: function(event, $carousel, itemIndex, $navContainer) {
        var $slide = $carousel.find('.owl-item');
        setTimeout(function(){
            // set aria hidden for arrows
            $navContainer.find('button').attr('aria-hidden', false);
            $navContainer.find('button.disabled').attr('aria-hidden', true);
            // set aria hidden for slides
            $slide.attr('aria-hidden', true);
            $slide.slice(itemIndex, itemIndex + event.page.size).attr('aria-hidden', false);
        },50);
    },

    /**
     * [setAriaLive] - sets and changes aria live text for slides
     * @param {[object]} event          : owl plugin event object
     * @param {[object]} $carousel      : carousel object
     */
    setAriaLive: function(event, $carousel) {
        var firstVisible = owl.module($carousel).loop === false ? event.item.index + 1 : event.page.index + 1,
            lastVisible = event.item.index + event.page.size,
            total = event.item.count,
            $liveRegion = $carousel.parent().find('.owl-live-region'),
            template = event.page.size > 1 ? 'Slides ' + firstVisible + ' through ' + lastVisible + ' of ' + total : 'Slide ' + firstVisible + ' of ' + total + ' (current)',
            liveSetting = $carousel.closest('.slideshow-container').find('*:focus').length === 0 ? 'off' : 'polite';

        // set aria live text & alert setting
        $liveRegion.attr('aria-live', liveSetting).text(template);
    },

    /**
     * [setBtnLabel] - sets aria label for prev/next buttons
     * @param {[object]} $carousel      : carousel object
     */
    setBtnLabel: function($carousel) {
        var $btnPrev = $carousel.siblings('.owl-nav').find('.owl-prev'),
            $btnNext = $carousel.siblings('.owl-nav').find('.owl-next'),
            headLineTxt = $carousel.parent('.carousel-container').data('headline') !== undefined ? ' for ' + $carousel.parent('.carousel-container').data('headline') : '';

        $btnPrev.attr('aria-label', 'Previous article' + headLineTxt);
        $btnNext.attr('aria-label', 'Next article' +  headLineTxt);
    },

    /**
     * [setDotsLabel] - sets aria label for slide dots
     * @param  {[object]} $dots [dots button container]
     */
    setDotsLabel: function($dots) {
        $dots.each(function(i) {
            var $btn = $(this).find('button'),
                slideNo = 'slide ' + (i + 1) + ' of ' + $dots.length;

            // set aria-label
            $btn.attr('aria-label', slideNo);
        });
    },

    /**
     * [swapIcons] - toggles between play and pause icons for both svg and pc icons
     * @param  {Object} $pauseBtn [paused button]
     * @param  'String' action    [pause or play]
     */
    swapIcons: function($pauseBtn, action) {
        var icon = {
            pause: '<svg class="svg-icon svg-icon-arrow"><use xlink:href="#icon-arrow" xmlns:xlink="http://www.w3.org/1999/xlink"></use></svg>',
            play: '<svg class="svg-icon svg-icon-pause"><use xlink:href="#icon-pause" xmlns:xlink="http://www.w3.org/1999/xlink"></use></svg>',
            toggle: action === 'pause' ? 'play' : 'pause'
        };

        if ($pauseBtn.find('svg').length > 0) {
            // for svg icons
            $pauseBtn.html(icon[action]);
        }
        else {
            // for pc icons
            $pauseBtn.find('span').text(icon.toggle).removeClass(action).addClass(icon.toggle);
        }
    }
}

$(function() {
    // initialize modules (formerly ships templates)
    if ($('.module-template').length > 0) {
        // iterate through each module
        $('.module-template').each(function() {
            var $module = $(this);
            // check for p13 content; if it exists, then we initialize modules after muation is observed
            if ($module.find('span[class^="p13nListItemId"]').length > 0) {
                // attach observer to the parent of the p13 item
                module.observeMutation($module.find('span[class^="p13nListItemId"]').parent());
            }
            else {
                // init modules
                module.init($module);
            }
        });
    }
    // initialize ship standalone
    if ($('#product-overview').length > 0) {
        ships.init();
    }
    // initialize ship facts
    if ($('.ship-facts-wrapper').length > 0) {
        ships.facts.init();
    }
});

/**
 * [ships]
 * @type {Object}
 */
var ships = {
    /**
     * [init]
     */
    init: function() {
        var $slideShow = $('.slideshow'),
            $tabs = $('button.product-gallery-buttons');

        // initialize slideshow
        if ($slideShow.length > 0) {
            module.global.owl($slideShow);
        }
        // toggle tab class
        module.global.toggleTabClass($('#product-gallery-nav'));
        // initialize tab button click
        $tabs.on('click', function() {
            var $btn = $(this),
                $tabContent = $('.product-gallery-options.' + $btn.data('option-class'));

            // toggle through tabs
            module.global.tabs($btn, null, $tabContent);
        });
        // init equalHeight on columns
        ships.columns();
        // equalHeight on resize
        $(window).on('windowResize', function() {
            ships.columns();
        });
    },
    /**
     * [columns]
     */
    columns: function() {
        var $column = $('.product-features-column'),
            $columnSiblings = $('.product-features-column + .product-features-column');

        if (Modernizr.mq('(min-width: 40em)')) {
            // column equalHeight and add border
            equalHeight($column, true);
            $columnSiblings.addClass('light-border-left');
        }
        else {
            // reset height and remove border
            $column.css('min-height', 0).removeClass('light-border-left');
        }
    },
    /**
     * [facts - handles ship facts]
     * @type {Object}
     */
    facts: {
        /**
         * [init]
         */
        init: function() {
            var $content = $('.ship-facts-wrapper');

            // 'more facts' button toggle
            $('.ship-show-toggle').on('click',function() {
                var $btn = $(this);

                if ($content.hasClass('hidden')) {
                    $content.removeClass('hidden');
                    $btn.addClass('active').text('Less Facts').attr('data-action', 'Close');
                    ships.facts.columns();
                }
                else {
                    $content.addClass('hidden');
                    $btn.removeClass('active').text('More Facts').attr('data-action', 'Open');
                }
            });
            // close button inside 'more facts'
            $('.ship-close > button').on('click',function() {
                var anchorOffsetTop = $('.ship-overview').offset().top - 75,
                    isOffScreen = !$('.ship-show-toggle').isOnScreen();

                // jump to anchor if offscreen
                if (isOffScreen) {
                    $('html,body').scrollTop(anchorOffsetTop);
                }
                // trigger hide
                $('.ship-show-toggle').trigger('click');
            });
            // window resize event
            $(window).on('windowResize', function() {
                ships.facts.columns();
            });
            // toggle article nav
            if (Modernizr.mq('(min-width: 40em)')) {
                $('nav.article-nav').removeClass('hidden');
            }
            else {
                $('nav.article-nav').addClass('hidden');
            }
        },
        /**
         * [columns]
         */
        columns: function() {
            var $columnContainer = $('.ship-info > div'),
                $column = $('.ship-info-column');

            // reset inline styling (note: can't seem to find the source in the first place)
            $columnContainer.css('padding-top', '0');
            // equalHeight and border adjustment
            if (Modernizr.mq('(min-width: 40em)')) {
                equalHeight($column, true);
                $('nav.article-nav').removeClass('hidden');
            }
            else {
                $columnContainer.first().find('.ship-info-column').css('border-top', '1px solid #d0d1d2');
                $column.css('min-height', '0');
                $('nav.article-nav').addClass('hidden');
            }
        }
    }
}

/**
 * [module]
 * @type {Object}
 */
var module = {
    /**
     * [init]
     * @param  {Object} $module [module container]
     */
    init: function($module) {
        var template = $module.data('module');
        // switch template
        switch(template) {
            case 'wide':
                module.wide.init(template, $module);
            break;
            case 'cards':
                module.cards.init(template, $module);
            break;
            case 'sbs':
                module.sbs.init(template, $module);
            break;
        }
    },
    /**
     * [wide - template]
     * @type {Object}
     */
    wide: {
        /**
         * [init]
         * @param  {String} template [template name]
         * @param  {Object} $module  [module container]
         */
        init: function(template, $module) {
            var $anchor = $module.find('a.tab-nav-link'),
                $mobileAnchor = $module.find('.wide-nav-link a'),
                $mobileClose = $module.find('.wide-nav-close > a');

            // initialize navigation
            module.wide.navigation(template, $module, $anchor, $mobileAnchor, $mobileClose);
            // initialize global functionality
            module.global.init(template, $module);
            // initialize module-specific functionality
            module.wide.postRender($module, $anchor);
            // initialize resize functionality
            module.wide.onResize(template, $module, $anchor);
        },
        /**
         * [navigation]
         * @param  {String} template      [template name]
         * @param  {Object} $module       [module container]
         * @param  {Object} $anchor       [tabnav anchor]
         * @param  {Object} $mobileAnchor [mobile anchor; not part of tabnav]
         * @param  {Object} $mobileClose  [mobile close button]
         */
        navigation: function(template, $module, $anchor, $mobileAnchor, $mobileClose) {
            // initialize tab nav button click
            $anchor.on('click keyup',function(e) {
                e.preventDefault();
                var $tab = $(this),
                    linkName = $tab.data('linkname'),
                    $content = $module.find('li[data-contentname="' + linkName + '"]'),
                    contentSrc = $content.data('contenttype'),
                    url = $tab.attr('href'),
                    $slideShow = $content.find('.slideshow');

                // keyboard functionality for tab nav
                module.navigation.keyboard($tab, e);
                // toggle content and/or async call
                if (!e.keyCode || e.keyCode == 13) {
                    module.navigation.init(template, $content, contentSrc, url, $module, $slideShow, 'desktop');
                }
            });
            // initialize mobile button click
            $mobileAnchor.on('click',function(e) {
                e.preventDefault();
                var $tab = $(this),
                    $content = $tab.closest('li'),
                    contentname = $content.data('contentname'),
                    contentSrc = $content.data('contenttype'),
                    url = $module.find('a[data-linkname="' + contentname + '"]').attr('href'),
                    $slideShow = $content.find('.slideshow');

                // toggle content and/or async call
                module.navigation.init(template, $content, contentSrc, url, $module, $slideShow, 'mobile');
            });
            // initialize mobile close
            $mobileClose.on('click',function(e) {
                e.preventDefault();
                var $content =  $(this).closest('li'),
                    $anchor = $content.find('.wide-nav-link a'),
                    $anchorOffsetTop = $content.find('.wide-nav-link').offset().top,
                    $features = $content.find('.product-show-container'),
                    $featuresBtn = $content.find('.product-show-toggle');

                // trigger mobile close
                module.navigation.close($anchor, $anchorOffsetTop, $features, $featuresBtn);
            });
        },
        /**
         * [postRender]
         * @param  {[type]} $module [container]
         * @param  {[type]} $anchor [tabnav anchor]
         */
        postRender: function($module, $anchor) {
            // toggle tab classes
            $module.find('.wide-content').each(function() {
                var $content = $(this);
                module.global.toggleTabClass($content);
            });
        },
        /**
         * [onResize]
         * @param  {String} template [template name]
         * @param  {Object} $module  [module container]
         * @param  {Object} $anchor  [tabnav anchor]
         */
        onResize: function(template, $module, $anchor) {
            // resize functionality
            $(window).on('windowResize', function() {
                var $featuresContent = $module.find('.product-show-container').not('.hidden'),
                    $column = $featuresContent.find('.product-features-column'),
                    $columnSiblings = $featuresContent.find('.product-features-column + .product-features-column');

                if (Modernizr.mq('(min-width: 40em)')) {
                    // column equalHeight and add border
                    equalHeight($column, true);
                    $columnSiblings.addClass('light-border-left');
                    // transition to desktop view
                    if (!$module.data('click-triggered')) {
                        // trigger first anchor click
                        $anchor.first().trigger('click');
                        // set click-triggered to true to prevent multiple triggers
                        $module.data('click-triggered', true);
                    }
                }
                else {
                    // reset transition trigger condition for desktop
                    $module.data('click-triggered', false);
                    // reset height and remove border
                    $column.css('min-height', 0).removeClass('light-border-left');
                }
            });
        }
    },
    /**
     * [cards - template]
     * @type {Object}
     */
    cards: {
        /**
         * [init]
         * @param  {String} template [template name]
         * @param  {Object} $module  [module container]
         */
        init: function(template, $module) {
            var $anchor = $module.find('.cards-nav-link > a'),
                $card = $module.find('.cards-nav-wrapper'),
                $mobileClose = $module.find('.cards-nav-close > a');

            // set data attribute for cards; we need this to determine rows
            for (var i = 0; i < $card.length; i += 3) {
                $card.slice(i, i + 3).attr('data-card-row', i);
            }
            // initialize navigation
            module.cards.navigation(template, $module, $anchor, $card, $mobileClose);
            // initialize global functionality
            module.global.init(template, $module);
            // initialize module-specific functionality
            module.cards.postRender($module, $anchor);
            // initialize resize functionality
            module.cards.onResize(template, $module);
        },
        /**
         * [navigation]
         * @param  {String} template     [template name]
         * @param  {Object} $module      [module container]
         * @param  {Object} $anchor      [thumbnail anchor]
         * @param  {Object} $card        [card content]
         * @param  {Object} $mobileClose [mobile close button]
         */
        navigation: function(template, $module, $anchor, $card, $mobileClose) {
            // initialize tab nav button click
            $anchor.on('click',function(e, i) {
                e.preventDefault();
                var $tab = $(this),
                    linkName = $tab.data('linkname'),
                    $content = $module.find('div[data-contentname="' + linkName + '"]'),
                    contentSrc = $content.data('contenttype'),
                    url = $tab.attr('href'),
                    $slideShow = $content.find('.slideshow'),
                    device = Modernizr.mq('(min-width: 40em)') ? 'desktop' : 'mobile';

                // toggle content and/or async call
                module.navigation.init(template, $content, contentSrc, url, $module, $slideShow, device);
            });
            
            // initialize mobile close
            $mobileClose.on('click',function(e) {
                e.preventDefault();
                var $content =  $(this).closest('article'),
                    $anchor = $content.find('.cards-nav-link a'),
                    $anchorOffsetTop = $content.find('.cards-nav-link').offset().top,
                    $features = $content.find('.product-show-container'),
                    $featuresBtn = $content.find('.product-show-toggle');

                // trigger mobile close
                module.navigation.close($anchor, $anchorOffsetTop, $features, $featuresBtn);
            });
        },
        /**
         * [postRender]
         * @param  {Object} $module [module container]
         * @param  {Object} $anchor [thumbnail anchor]
         */
        postRender: function($module, $anchor) {
            // toggle tab classes
            $module.find('.cards-content').each(function() {
                var $content = $(this);
                module.global.toggleTabClass($content);
            });
        },
        /**
         * [onResize]
         * @param  {String} template [template name]
         * @param  {Object} $module  [module container]
         */
        onResize: function(template, $module) {
            // resize functionality
            $(window).on('windowResize', function() {
                var $featuresContent = $module.find('.product-show-container').not('.hidden'),
                    $column = $featuresContent.find('.product-features-column'),
                    $columnSiblings = $featuresContent.find('.product-features-column + .product-features-column'),
                    $card = $module.find('.cards-nav-wrapper.active'),
                    $content = $module.find('.cards-nav-wrapper > .cards-content'),
                    $activeContent = $module.find('.cards-nav-wrapper.active > .cards-content');

                if (Modernizr.mq('(min-width: 40em)')) {
                    // column equalHeight and add border
                    equalHeight($column, true);
                    $columnSiblings.addClass('light-border-left');
                    // transition to desktop view

                    if (!$module.data('click-triggered')) {
                        // trigger first anchor click
                        $card.removeClass('active').find('.cards-content').css('display','');
                        // set click-triggered to true to prevent multiple triggers
                        $module.data('click-triggered', true);
                    }
                }
                else {
                    // reset transition trigger condition for desktop
                    $module.data('click-triggered', false);
                    // reset height and remove border
                    $column.css('min-height', 0).removeClass('light-border-left');
                }

                if ($activeContent.length > 0) {
                    // set card background css
                    $activeContent.each(function() {
                        module.cards.setCardBg($(this));
                    });
                }
                // set resize condition for card transition
                $content.data('cardbg-adjusted', false);
            });
        },
        /**
         * [setCardBg]
         * @param  {Object} $content [card content]
         */
        setCardBg: function($content) {
            var $cardsContainer = $content.closest('.cards-nav'),
                $card = $content.closest('article'),
                $width = $cardsContainer.width() - 30,
                $offset = $card.offset().left - $cardsContainer.offset().left;

            if (Modernizr.mq('(min-width: 40em)')) {
                $content.css('width', $width).css('margin-left', -$offset).css('top','15px').css('overflow','visible');
            }
            else {
                $content.css('width', 'initial').css('margin-left', 'initial').css('top','initial');
            }
            // reset resize condition for card transition
            $content.data('cardbg-adjusted', true);
        },
    },
    /**
     * [sbs - template]
     * @type {Object}
     */
    sbs: {
        /**
         * [init]
         * @param  {String} template [template name]
         * @param  {Object} $module  [module container]
         */
        init: function(template, $module) {
            var $anchor = $module.find('a.tab-nav-link'),
                $mobileAnchor = $module.find('.sbs-mobile-nav a'),
                $mobileClose = $module.find('.sbs-nav-close > a');

            // initialize navigation
            module.sbs.navigation(template, $module, $anchor, $mobileAnchor, $mobileClose);
            // initialize global functionality
            module.global.init(template, $module);
            // initialize module-specific functionality
            module.sbs.postRender($module, $anchor);
            // initialize resize functionality
            module.sbs.onResize(template, $module, $anchor);
        },
        /**
         * [navigation]
         * @param  {String} template      [template name]
         * @param  {Object} $module       [module container]
         * @param  {Object} $anchor       [tabnav anchor]
         * @param  {Object} $mobileAnchor [mobile anchor; not part of tabnav]
         * @param  {Object} $mobileClose  [mobile close button]
         */
        navigation: function(template, $module, $anchor, $mobileAnchor, $mobileClose) {
            // initialize tab nav button click
            $anchor.on('click keyup', function(e) {
                e.preventDefault();
                var $tab = $(this),
                    linkName = $tab.data('linkname'),
                    $content = $module.find('div[data-contentname="' + linkName + '"]'),
                    contentSrc = $content.data('contenttype'),
                    url = $tab.attr('href'),
                    $slideShow = $content.find('.slideshow');

                // keyboard functionality for tab nav
                module.navigation.keyboard($tab, e);
                // toggle content and/or async call
                if (!e.keyCode || e.keyCode == 13) {
                    module.navigation.init(template, $content, contentSrc, url, $module, $slideShow, 'desktop');
                }
            });
            // initialize mobile button click
            $mobileAnchor.on('click',function(e) {
                e.preventDefault();
                var $tab = $(this),
                    $content = $tab.closest('.sbs-content'),
                    contentname = $content.data('contentname'),
                    contentSrc = $content.data('contenttype'),
                    url = $module.find('a[data-linkname="' + contentname + '"]').attr('href'),
                    $slideShow = $content.find('.slideshow');

                // toggle content and/or async call
                module.navigation.init(template, $content, contentSrc, url, $module, $slideShow, 'mobile');
            });
            // initialize mobile close
            $mobileClose.on('click',function(e) {
                e.preventDefault();
                var $content =  $(this).closest('.sbs-content'),
                    $anchor = $content.find('.sbs-mobile-nav a'),
                    $anchorOffsetTop = $content.find('.sbs-mobile-nav').offset().top,
                    $features = $content.find('.product-show-container'),
                    $featuresBtn = $content.find('.product-show-toggle');

                // trigger mobile close
                module.navigation.close($anchor, $anchorOffsetTop, $features, $featuresBtn);
            });
        },
        /**
         * [postRender]
         * @param  {Object} $module [module container]
         * @param  {Object} $anchor [tabnav anchor]
         */
        postRender: function($module, $anchor) {
            if (Modernizr.mq('(min-width: 40em)')) {
                // hide secondary image if tab nav is too long
                if ($module.find('.sbs-nav').height() > 400) {
                    $module.find('.sbs-secondary-img').addClass('sbs-hidden');
                }
            }
            // add or remove padding depending on number of items and viewport (desktop only)
            if (Modernizr.mq('(min-width: 48em)') && $module.find('.tab-nav-link').length <= 7) {
                 $module.find('.sbs-nav').addClass('pad-50');
            }
            // toggle tab classes
            $module.find('.sbs-content').each(function() {
                var $content = $(this);
                module.global.toggleTabClass($content);
            });
        },
        /**
         * [onResize]
         * @param  {String} template [template name]
         * @param  {Object} $module  [module container]
         * @param  {Object} $anchor  [tabnav anchor]
         */
        onResize: function(template, $module, $anchor) {
            // resize functionality
            $(window).on('windowResize', function() {
                var $featuresContent = $module.find('.product-show-container').not('.hidden'),
                    $column = $featuresContent.find('.product-features-column'),
                    $columnSiblings = $featuresContent.find('.product-features-column + .product-features-column'),
                    $nav = $module.find('.sbs-nav');

                if (Modernizr.mq('(min-width: 40em)')) {
                    // column equalHeight and add border
                    equalHeight($column, true);
                    $columnSiblings.addClass('light-border-left');
                    // transition to desktop view
                    if (!$module.data('click-triggered')) {
                        // trigger first anchor click
                        $anchor.first().trigger('click');
                        // set click-triggered to true to prevent multiple triggers
                        $module.data('click-triggered', true);
                    }
                    // hide secondary image if tab nav is too long
                    if ($nav.height() > 400) {
                        $module.find('.sbs-secondary-img').addClass('sbs-hidden');
                    }
                }
                else {
                    // reset transition trigger condition for desktop
                    $module.data('click-triggered', false);
                    // reset height and remove border
                    $column.css('min-height', 0).removeClass('light-border-left');
                }
                // add/remove padding depending on number of items and viewport
                if (Modernizr.mq('(min-width: 48em)') && $module.find('.tab-nav-link').length <= 7) {
                    $nav.addClass('pad-50');
                }
                else {
                    $nav.removeClass('pad-50');
                }
            });
        }
    },
    /**
     * [async - request and render async content]
     * @type {Object}
     */
    async: {
        /**
         * [init]
         * @param  {String} url      [url of content]
         * @param  {String} template [template name]
         * @param  {Object} $content [module's content]
         */
        init: function(url, template, $content) {
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'html'
            })
            .done(function(data) {
                module.async.render($(data), template, $content);
            });
        },
        /**
         * [render]
         * @param  {Object} $data    [async data]
         * @param  {String} template [template name]
         * @param  {Object} $content [module's content]
         */
        render: function($data, template, $content) {
            var gallery = $data.find('#product-gallery-content').html(),
                nav = $data.find('#product-gallery-nav').html(),
                header = $data.find('#product-overview-text :header').html(),
                desc = $data.find('#product-overview-text p:first-of-type').html(),
                features = $data.find('#product-features').html(),
                thumbnail = $data.find('#product-overview-text > div').html(),
                $additionalDesc = $data.find('#product-overview-text p:first-of-type').nextAll();

            // default imports
            $content.find('.' + template + '-media-gallery').html(gallery);
            $content.find('.' + template + '-media-tabs').html(nav);
            $content.find('.' + template + '-overview :header').html(header);
            $content.find('.' + template + '-overview p').html(desc);
            $content.find('.' + template + '-features').html(features);
            // additional imports
            if ($additionalDesc.length > 0) {
                $additionalDesc.each(function() {
                    var outerHtml = $(this)[0].outerHTML;
                    $content.find('.' + template + '-overview').append(outerHtml);
                });
            }
            // conditional imports
            switch(template) {
                case 'sbs':
                    $content.find('.' + template + '-secondary-img').html(thumbnail);
                    break;
                case 'cards':
                    $content.find('.' + template + '-overview').prepend('<div class="img-responsive-wrapper">' + thumbnail + '</div>');
                    break;
            }
            // trigger click, if drawer is already opened on load (workaround)
            if (!$content.find('.product-show-container').hasClass('hidden')) {
                $content.find('.product-show-toggle').trigger('click');
            }
            // initialize post render functionality
            module.async.postRender($content);
        },
        /**
         * [postRender]
         * @param  {Object} $content [module's content]
         */
        postRender: function($content) {
            var $slideShow = $content.find('.slideshow'),
                $adVideo = $content.find('.video-accessible'),
                $virtualTourAnchor = $content.find('.vtour-anchor');

            // toggle tab classes
            module.global.toggleTabClass($content);
            // initialize slideshow
            $slideShow.length && module.global.owl($slideShow);
            // initialize ad bar
            $adVideo.length && audioDesc.init($adVideo);
            // initialize virtual tour
            if ($virtualTourAnchor.length) {
                // bind anchor
                $virtualTourAnchor.on('click',function(e) {
                    e.preventDefault();
                    var $self = $(this),
                        breakpoint = windowCat(window.innerWidth),
                        iframe = "<div class='video-responsive'><iframe width='960' height='540' src='" + $self.attr('href') + "' frameborder='0' allow='vr' allowfullscreen='allowfullscreen'></iframe></div>";
        
                    if (breakpoint === 'xs') {
                        $self.addClass('open-mfp');
                    }
                    else {
                        $self.removeClass('open-mfp')
                            .append(iframe)
                            .find('.vtour-container')
                            .addClass('hidden');
                    }
                });
                // clear on viewport transition
                $(window).on('windowResize', function() {
                    var breakpoint = windowCat(window.innerWidth),
                        $container = $content.find('.vtour-container'),
                        $lightbox = $('.vtours-lightbox');
        
                    if (breakpoint === 'xs') {
                        if ($container.hasClass('hidden')) {
                            $container.removeClass('hidden')
                                .siblings('.video-responsive')
                                .remove();
                        }
                    }
                    else {
                        if ($lightbox.length) {
                            $lightbox.trigger('click');
                        }
                    }
                });
            }
        }
    },
    /**
     * [navigation - handles navigation clicks]
     * @type {Object}
     */
    navigation: {
        /**
         * [init]
         * @param  {String} template   [template name]
         * @param  {Object} $content   [module's content]
         * @param  {String} contentSrc [content type; either embed or async]
         * @param  {String} url        [url of content]
         * @param  {Object} $module    [module container]
         * @param  {Object} $slideShow [slideshow]
         * @param  {String} device     [viewport; either desktop or mobile]
         */
        init: function(template, $content, contentSrc, url, $module, $slideShow, device) {
            // initialize slideshow
            if ($slideShow.length > 0) {
                module.global.owl($slideShow);
            }
            else {
                $content.find('.product-gallery-options').removeClass('not-ready');
            }
            // initialize async
            if (!$content.data('initialized') && contentSrc == 'async') {
                module.async.init(url, template, $content);
                // set initialized data attribute true to prevent duplicate initializations
                $content.data('initialized', true);
            }
            // toggle show hide
            switch(template) {
                case 'wide':
                    // show hide functionality
                    module.navigation.wide(template, $content, contentSrc, url, $module, $slideShow, device);
                break;
                case 'cards':
                    // show hide functionality
                    module.navigation.cards(template, $content, contentSrc, url, $module, $slideShow, device);
                break;
                case 'sbs':
                    // show hide functionality
                    module.navigation.sbs(template, $content, contentSrc, url, $module, $slideShow, device);
                break;
            }
        },
        /**
         * [wide]
         * @param  {String} template   [template name]
         * @param  {Object} $content   [module's content]
         * @param  {String} contentSrc [content type; either embed or async]
         * @param  {String} url        [url of content]
         * @param  {Object} $module    [module container]
         * @param  {Object} $slideShow [slideshow]
         * @param  {String} device     [viewport; either desktop or mobile]
         */
        wide: function(template, $content, contentSrc, url, $module, $slideShow, device) {
            switch(device) {
                case "mobile":
                    if ($content.hasClass('active')) {
                        // close
                        $content.find('.wide-content').slideUp(400, function() {
                            // reset in-line style caused by slide()
                            $(this).css('display', '');
                        });
                        // set aria-expanded
                        $content.removeClass('active').find('.wide-nav-link > a').attr('aria-expanded', 'false');
                    }
                    else {
                        // open
                        $content.find('.wide-content').slideDown(400);
                        // set aria-expanded
                        $content.addClass('active').find('.wide-nav-link > a').attr('aria-expanded', 'true');
                    }
                break;
                case "desktop":
                    setTimeout(function() {
                        $content.addClass('active')
                        .siblings('li[data-contentname]').removeClass('active')
                        .find('.wide-content').css('display','');
                    }, 100);
                break;
            }
        },
        /**
         * [cards]
         * @param  {String} template   [template name]
         * @param  {Object} $content   [module's content]
         * @param  {String} contentSrc [content type; either embed or async]
         * @param  {String} url        [url of content]
         * @param  {Object} $module    [module container]
         * @param  {Object} $slideShow [slideshow]
         * @param  {String} device     [viewport; either desktop or mobile]
         */
        cards: function(template, $content, contentSrc, url, $module, $slideShow, device) {
            var $card = $content.closest('article'),
                row = $card.data('card-row');

            // toggle show hide
            switch(device) {
                case "mobile":
                    if ($card.hasClass('active')) {
                        // close card
                        $content.slideUp(400, function() {
                            // reset in-line style caused by slide()
                            $(this).css('display','');
                        });
                        // set aria expanded
                        $card.removeClass('active').find('.cards-nav-link > a').attr('aria-expanded', 'false');
                    }
                    else {
                        // open card
                        $content.slideDown(400);
                        // set aria expanded
                        $card.addClass('active').find('.cards-nav-link > a').attr('aria-expanded', 'true');
                    }
                break;
                case "desktop":
                    
                    if ($card.hasClass('active')) {
                        // close card and set aria expanded
                        $card.removeClass('active').find('.cards-nav-link > a').attr('aria-expanded', 'false');
                    }
                    else {
                        // remove active class from other cards
                        $card.siblings('[data-card-row="' + row + '"]').removeClass('active');
                        // reset aria-expanded for other cards
                        $card.siblings('[data-card-row="' + row + '"]').find('.cards-nav-link > a').attr('aria-expanded', 'false');
                        // set active class for current card and update aria expanded
                        $card.addClass('active').find('.cards-nav-link > a').attr('aria-expanded', 'true');

                    }
                    
                break;
            }
            // initialize card background size adjustment
            if (!$content.data('cardbg-adjusted')) {
                module.cards.setCardBg($content);
            }
        },
        /**
         * [sbs]
         * @param  {String} template   [template name]
         * @param  {Object} $content   [module's content]
         * @param  {String} contentSrc [content type; either embed or async]
         * @param  {String} url        [url of content]
         * @param  {Object} $module    [module container]
         * @param  {Object} $slideShow [slideshow]
         * @param  {String} device     [viewport; either desktop or mobile]
         */
        sbs: function(template, $content, contentSrc, url, $module, $slideShow, device) {
            switch(device) {
                case "mobile":
                    if ($content.hasClass('active')) {
                        $content.find('.sbs-nav-content').slideUp(400, function() {
                            // reset in-line style caused by slide()
                            $(this).css('display','');
                        });
                        $content.removeClass('active').find('.sbs-mobile-nav a').attr('aria-expanded', 'false');
                    }
                    else {
                        $content.find('.sbs-nav-content').slideDown(400);
                        $content.addClass('active').find('.sbs-mobile-nav a').attr('aria-expanded', 'true');
                    }
                break;
                case "desktop":
                    setTimeout(function() {
                        $content.addClass('active')
                        .siblings('div[data-contentname]').removeClass('active')
                        .find('.sbs-nav-content').css('display','');
                    }, 100);
                break;
            }
        },
        /**
         * [close]
         * @param  {Object} $anchor          [close button]
         * @param  {Object} $anchorOffsetTop [offset of the button]
         * @param  {Object} $features        [features container]
         * @param  {Object} $featuresBtn     [features toggle button]
         */
        close: function($anchor, $anchorOffsetTop, $features, $featuresBtn) {
            // trigger anchor click
            $anchor.trigger('click');
            // scroll to anchor to prevent jumping
            $('html,body').scrollTop($anchorOffsetTop - 50);
            // trigger features click if content is open
            if (!$features.hasClass('hidden')) {
                $featuresBtn.trigger('click');
            }
        },
        /**
         * [keyboard - handles keyboard functionality for accessible tab navs]
         * @param  {Object} $tab [anchor]
         * @param  {event}  e    [anchor event]
         */
        keyboard: function($tab, e) {
            // left arrow controls for ada
            if (e.keyCode == 37 || e.keyCode == 38) {
                var previous = $tab[0].previousElementSibling;

                if (previous) {
                    // focus on previous anchor
                    $(previous).trigger('focus');
                }
                else {
                    // wrap around to last
                    $tab.siblings('a').last().trigger('focus');
                }
            }
            // right arrow controls for ada
            if (e.keyCode == 39 || e.keyCode == 40) {
                var next = $tab[0].nextElementSibling;

                if (next) {
                    // focus on previous anchor
                    $(next).trigger('focus');
                }
                else {
                    // wrap around to first
                    $tab.siblings('a').first().trigger('focus');
                }
            }
            // on click or enter
            if (!e.keyCode || e.keyCode == 13) {
                // change aria-selected
                $tab.attr('aria-selected', true).siblings('a').attr('aria-selected', false);
                // allow content focus on next tab; we do this instead of directly focusing content to prevent jumping
                $tab.attr('tabindex','0').siblings('a').attr('tabindex','-1');
            }
        }
    },
    /**
     * [global - handles global functionality across all modules]
     * @type {Object}
     */
    global: {
        /**
         * [init]
         * @param  {String} template [template name]
         * @param  {Object} $module  [module container]
         */
        init: function(template, $module) {
            var $tabContainer = $module.find('.' + template + '-media-tabs'),
                $featuresContainer = $module.find('.' + template + '-features');

            // initialize tab button click
            $tabContainer.on('click','button.product-gallery-buttons', function() {
                var $btn = $(this),
                    $content = $btn.closest('.' + template + '-content'),
                    $tabContent = $content.find('.product-gallery-options.' + $btn.data('option-class'));

                // toggle through tabs
                module.global.tabs($btn, $content, $tabContent);
            });
            // initialize features button click
            $featuresContainer.on('click', 'button.product-show-toggle, button.product-close', function() {
                var $btn = $(this),
                    $content = $btn.closest('.' + template + '-content'),
                    $featuresContent = $content.find('.product-show-container');

                // toggle through content
                module.global.features(
                    $btn.hasClass('product-close') ? $content.find('button.product-show-toggle') : $btn,
                    $featuresContent
                );
            });
            // trigger click, if drawer is already opened on load (workaround)
            if (!$module.find('.product-show-container').hasClass('hidden')) {
                $module.find('.product-show-toggle').trigger('click');
            }
            // trigger first tab nav link to show content on load
            if (Modernizr.mq('(min-width: 40em)') && !$module.data('click-triggered')) {
                // check to see if tabnav exists
                if ($module.find('.tab-nav-link').length > 0) {
                    // delay trigger to make sure dom changes are ready
                    setTimeout(function() {
                        // trigger first click
                        $module.find('.tab-nav-link').first().trigger('click');
                        // set data attribute; we need this to prevent multiple fires
                        $module.data('click-triggered', true);
                    },50)
                }
            }
        },
        /**
         * [tabs]
         * @param  {Object} $btn        [tab button]
         * @param  {Object} $content    [content container]
         * @param  {Object} $tabContent [tab content]
         */
        tabs: function($btn, $content, $tabContent) {
            var $audioDescBar = $tabContent.find('.audio-desc-bar'),
                $videoContainer = $tabContent.find('.video-responsive');

            // toggle button active class
            $btn.addClass('active').siblings('button').removeClass('active');
            // toggle hidden class for content
            $tabContent.removeClass('hidden').siblings('.product-gallery-options').addClass('hidden');
            // audio desc visibility for videos
            if (!$audioDescBar.hasClass('initialized')) {
                setTimeout(function() {
                    $audioDescBar.slideDown('1000', function() {
                        $videoContainer.attr('tabindex','0').trigger('focus');
                    }).addClass('initialized');
                }, 250)
            }
        },
        /**
         * [features]
         * @param  {[type]} $btn             [features toggle button]
         * @param  {[type]} $featuresContent [features content]
         */
        features: function($btn, $featuresContent) {
            var $column = $featuresContent.find('.product-features-column');

            // toggle hidden class and button text
            if ($featuresContent.hasClass('hidden')) {
                // show features content
                $featuresContent.removeClass('hidden');
                $btn.addClass('active').text($btn.data('show-less'));
                // adjust column height
                if (Modernizr.mq('(min-width: 40em)')) {
                    equalHeight($column, true);
                }
                else {
                    $column.removeClass('light-border-left').css('min-height', 0);
                }
            }
            else {
                // hide features content
                $featuresContent.addClass('hidden');
                $btn.removeClass('active').text($btn.data('show-more'));
            }
        },
        /**
         * [owl]
         * @param  {[type]} $slideShow [slideshow]
         */
        owl: function($slideShow) {
            // prevent duplicate initialization
            if ($slideShow.data('initialized')) {
                return false;
            }

            var owlMarkup = '<div class="owl-media"><button class="owl-play-pause"><span class="ir pause">Pause</span></button></div><div class="owl-dots"></div><div aria-live="polite" class="owl-live-region visuallyhidden" role="region"></div>',
                count = 0;

            // check to see if there's only one slide
            if ($slideShow.find('.slideshow-item').length == 1) {
                // set single slideshow initialized
                $slideShow.data('initialized', true);
                // remove not-ready class
                $slideShow.closest('.not-ready').removeClass('not-ready');
                return false;
            }
            // add owl-carousel class and media controls markup
            if (!$slideShow.hasClass('owl-carousel')) {
                $slideShow.addClass('owl-carousel').after(owlMarkup);
            }
            // initialize owl
            owl.check();
            // remove not-ready when fully initialized; need to do this to prevent stutter caused by the initialization
            var owlChecker = setInterval(function() {
                // owl-item.cloned is resulting element from initialization, therefore we check for this
                if ($slideShow.find('.owl-item.cloned').length > 0) {
                    $slideShow.closest('.not-ready').removeClass('not-ready');
                    clearInterval(owlChecker);
                }
                // clearInterval failsafe
                count < 500
                    ? count++
                    : clearInterval(owlChecker);
            },10)
        },
        /**
         * [toggleTabClass]
         * @param  {Object} $content    [content container]
         */
        toggleTabClass: function($content) {
            var $tab = $content.find('.product-options-wrapper > button');

            if ($tab.length == 1) {
                $tab.addClass('hidden').closest('.product-options-wrapper').removeClass('col-xs-pad-10-top');
            }
            else {
                $tab.first().addClass('active');
            }
        }
    },
    /**
     * [observeMutation - mutation observer for localized p13 content]
     * @param  {object} $content [parent object of p13 span placeholder]
     */
    observeMutation: function($content) {
        var $module = $content.closest('.module-template'),
            options = {childList: true},
            p13Count = $content.find('span[class^="p13nListItemId"]').length,
            recordsCount = 0;
        
        // set up new observer
        var observer = new MutationObserver(function(mutations) {
            // iterate through mutations
            mutations.forEach(function(mutation) {
                // check for removed P13 nodes
                if (mutation.removedNodes.length) {
                    var removedNodes = Array.prototype.slice.call(mutation.removedNodes);
                    removedNodes.forEach(function(node) {
                        node.className.indexOf('p13nListItemId') > -1 && recordsCount++;
                    });
                }
                if (recordsCount === p13Count) {
                    module.init($module);
                    observer.disconnect();
                }
            });
        });
        // initialize observe
        observer.observe($content[0], options);
    }
}
/**
 * [vertAlignCheck - initiate vertical alignment after certain conditions are met]
 * @type {Object}
 */
var vertAlignCheck = {
    /**
     * [count - global counter; we need this to check for last mutation]
     * @type {Number}
     */
    count: 0,
    /**
     * [init - initialize series of checks for alignment]
     * @param  {object} $columns [objects with class vert-align-col]
     */
    init: function($columns) {
        // determine if we need to wait for images to vertically align
        $columns.each(function() {
            var $img = $(this).find('img');

            if ($img.length > 0) {
                // align content after image load
                vertAlignCheck.imgCheck($img);
            }
            else {
                // align content
                vertAlignCol($(this));
            }
        })
        // mutation observer to observe p13n content
        if ($("span[class^='p13nListItemId']").length > 0) {
            vertAlignCheck.observeMutation();
        }
        // bind window resize event
        $(window).on('windowResize', function() {
        	vertAlignCol('.vert-align-col');
        });
    },
    /**
     * [imgCheck - check to see if images are complete; if not bind load]
     * @param  {object} $img     [image object]
     * @param  {object} observer [mutation observer]
     * @param  {bool}   mutation [is object a mutation]
     */
    imgCheck: function($img, observer, mutation) {
        if ($img[0].complete) {
            // check to see if img is supposed to be lazy loaded
            if ($img.hasClass('lazyload')) {
                // bind lazyload event
                $img.on('lazyloaded', function() {
                    // align lazy loaded img
                    vertAlignCol($img.closest('.vert-align-col'));
                });
            }
            else {
                // align if img is complete
                vertAlignCol($img.closest('.vert-align-col'));
            }
        }
        else {
            // bind load and error events if img is not complete
            $img.on('load error', function(e) {
                // align content
                vertAlignCol($img.closest('.vert-align-col'));
                // check to see if coming from observer
                if (mutation) {
                    // increase global count; we need this to check for last mutation
                    vertAlignCheck.count++;
                    // disconnect observer on last mutation
                    if (vertAlignCheck.count == $('.mutation').length) {
                        observer.disconnect();
                    }
                }
            });
        }
    },
    /**
     * [observeMutation - set up observer and watch for dom mutations]
     */
    observeMutation: function() {
        var target = document.querySelector('.article-container > .container'),
            options = {attributes: true, childList: true, characterData: true};

        // set up new observer
        var observer = new MutationObserver(function(mutations) {
            // iterate through mutations
            mutations.map(function(mutation) {
                // filter out only added mutations
                if (mutation.addedNodes.length > 0) {
                    // iterate through added nodes
                    Array.prototype.forEach.call(mutation.addedNodes, function(node, i){
                        var classExists = $(node).hasClass('vert-align-col') || $(node).find('.vert-align-col').length > 0;
                        // filter out non dom elements and non vert-align-col nodes
                        if (typeof node.getElementsByTagName !== 'function' || !classExists) {
                            return;
                        }
                        // image selector based on location of vert align class
                        var $img = $(node).hasClass('vert-align-col') ? $(node).find('img') : $(node).find('.vert-align-col img');
                        // return if img from p13 does not exist
                        if ($img.length > 0) {
                            // add mutation class so we can check for length in imgCheck()
                            $img.addClass('mutation');
                            // align content after image load
                            vertAlignCheck.imgCheck($img, observer, true);
                        }
                    });
                }
            });
        });

        // initialize observe
        if (target) {
            observer.observe(target, options);
        }
    }
}

/**
 * [ugc - initializes lazyloading of ugc]
 * @type {Object}
 */
var ugc = {
    /**
     * [checkLocale - check for localeData]
     * @param  {Object} $container [ugc container]
     */
    checkLocale: function($container) {
        if (!localeData) {
            $(document).on('getLocale', function () {
                ugc.init($container);
            });
        }
        else {
            ugc.init($container);
        }
    },
		/**
     * [init - creates and appends script once in viewport]
     * @param  {Object} $container [ugc container]
     */
    init: function($container) {
        var isLoaded = false;

        // prevent load for CN
        if (localeData.country == 'CN') {
            var $headline = $container.prev('.headline-bar');
            // hide headline for CN
            $headline.addClass('hidden');
            return false;
        }
        // remove hidden class from container
        $container.removeClass('hidden');
        // bind custom scroll event
        $(document).on('windowScroll.ugc', function() {
            var onScreen = $container.isOnScreen(700);
						if (onScreen && !isLoaded) {
                var script = document.createElement("script"),
                    src = $container.data('ugc-src'),
                    id = $container.data('ugc-id');

                // construct and append script
                script.src = src;
                script.id = id;
                $container[0].appendChild(script);
                // hide skeleton markup
                ugc.hideSkeleton($container);
                // set var to prevent duplicate fires
                isLoaded = true;
                // unbind custom windowScroll event
                $(document).off('windowScroll.ugc');
            }
						 if(typeof _satellite === 'object' && _satellite.track && isLoaded){
							  // satellite tracking
							    _satellite.track("UGCIsLoaded");
						 }
        });
    },
    /**
     * [hideSkeleton - hide skeleton markup when images are detected in the DOM]
     * @param  {Object} $container [ugc container]
     */
    hideSkeleton: function($container) {
        var count = 0,
            interval = setInterval(function() {
                if ($container.find('img').length > 0) {
                    $container.find('.ugc-skeleton').addClass('hidden');
                    clearInterval(interval);
                }
                if (count < 100) {
                    count++;
                }
                else {
                    clearInterval(interval);
                }
            },100);
    }
}
